<template>
  <v-card :loading="loading">
    <v-card-title class="headline">
      {{ selectedItem ? "Editar" : "Nuevo" }} contrato
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>
      <v-row class="mt-5 ma-3">
        <v-col cols="12" sm="12" md="12">
          <h2>Código del contrato</h2>
          <input
            class="control-input"
            placeholder="Código del contrato"
            v-model="item.code"
          />
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Fecha de inicio</h2>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <input
                class="control-input"
                v-on="on"
                type="text"
                placeholder="Fecha que inicia"
                v-model="date[0]"
              />
            </template>
            <v-date-picker
              v-model="date[0]"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Fecha fin</h2>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <input
                class="control-input"
                v-on="on"
                type="text"
                placeholder="Fecha fin"
                v-model="date[1]"
              />
            </template>
            <v-date-picker
              v-model="date[1]"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <h2>Seleccionar archivo</h2>
          <v-file-input
            rounded
            filled
            clear-icon
            show-size
            @change="handleFile"
            label=""
            accept=".pdf"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="12" md="12">
          <v-row justify="end">
            <v-btn
              class="save-btn"
              :loading="loading"
              @click="save"
              color="primary"
              dark
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mapPicker from "@/components/map-picker";
import { db, fb } from "@/firebase";

export default {
  components: {
    mapPicker,
  },
  name: "edit-contract",
  props: ["businessId", "selectedItem"],
  data() {
    return {
      item: {
        active: true,
        deleted: false,
      },
      loading: true,
      snackbarText: "",
      snackbar: false,
      menu1: false,
      menu2: false,
      date: [],
      file: null,
    };
  },
  methods: {
    async save() {
      if (this.item.code && this.date[0] && this.date[1] && this.file) {
        this.loading = true;
        let ref = db
          .collection("businessesCorporative")
          .doc(this.businessId)
          .collection("documents")
          .doc();

        let url = await this.uploadFile(ref.id);

        let data = {
          code: this.item.code,
          createdAt: new Date(),
          createdBy: this.$store.state.user[".key"],
          startDate: this.generateTimestamp(this.date[0]),
          endDate: this.generateTimestamp(this.date[1]),
          url,
          deleted: false,
        };

        ref
          .set(data)
          .then((res) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Debe completar todos los campos.";
        this.snackbar = true;
      }
    },

    uploadFile(docId) {
      return new Promise((resolve, reject) => {
        console.log(docId);
        var uploadTask = fb
          .app()
          .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-documents`)
          .ref()
          .child(`${this.businessId}/${docId}/doc.pdf`)
          .put(this.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    },

    handleFile(e) {
      if (e) {
        this.file = e;
      }
    },

    generateTimestamp(date) {
      let splitDate = date.split("-");
      return fb.firestore.Timestamp.fromDate(
        new Date(
          parseInt(splitDate[0]),
          parseInt(splitDate[1]) - 1,
          parseInt(splitDate[2]),
          parseInt("07"),
          parseInt("00"),
          parseInt("00")
        )
      );
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
    }
    this.loading = false;
  },
};
</script>